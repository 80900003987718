import React, { useEffect, useState } from "react";
import { Button, Nav, NavLink, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { RootState } from "redux/reducers";
import { sidebarToggle, activateMenu } from "redux/actions";
import { useAppDispatch } from "redux/store";
import { MenuItems } from "./MenuItems";
const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const handleActiveMenu = (menuName: string) => {
    dispatch(activateMenu(menuName));
  };
  const [loading, setLoading] = useState(false);
  const isSidebar = useSelector((state: RootState) => state.template.sidebar);
  const isActiveMenu = useSelector(
    (state: RootState) => state.template.activeMenu
  );
  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("user");
  const userProvince = localStorage.getItem("province_name");
  const userCity = localStorage.getItem("city_name");
  const periodsYear = localStorage.getItem("periode_year");
  const periodsMonth = localStorage.getItem("periode_month");
  const periodsId = localStorage.getItem("periode_id");

  const handleMenuClick = (path) => {
    dispatch(activateMenu(path));
    navigate(path, { replace: true });
  };
  const handleSubMenuClick = (path) => {
    navigate(path, { replace: true });
    window.location.reload();
  };
  const filteredMenuItems = MenuItems.filter((item) => {
    return !item.hidden;
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    // navigate('/dashboard', { replace: true });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (windowWidth < 768) {
      dispatch(sidebarToggle());
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (location?.state?.from === "login") {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
    document.body.classList.toggle("sidebar-toggled", isSidebar);
  }, [isSidebar, windowWidth]);

  return (
    <div className="">
      <div className="d-md-flex d-none">
        <img
          src="../logos/logo-bingkis.png"
          alt=""
          width={isSidebar ? 100 : 200}
          className={`d-none d-md-block my-3 ${isSidebar ? 'mx-4' : 'mx-5'}`}
        />
        <i
          className="fas fa-bars ml-auto mr-3 mt-4 cursor-pointer text-primary"
          onClick={() => dispatch(sidebarToggle())}
        ></i>
      </div>
      <ul
        className={
          isSidebar
            ? "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
            : "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        }
        id="accordionSidebar"
      >
        <div className="mt-4"></div>
        {/* {!isSidebar && (
          <div
            className="p-3 m-3 mx-auto d-none d-md-block"
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              maxWidth: "220px",
            }}>
            <div
              style={{
                color: "black",
                fontSize: "14px",
                wordWrap: "break-word",
              }}>
              {userEmail}
            </div>
            <div
              style={{
                color: "black",
                fontSize: "12px",
                wordWrap: "break-word",
              }}>
              <span style={{ fontWeight: "bold" }}>Role:</span>
              <br />
              {userRole}
            </div>
            <div
              style={{
                color: "black",
                fontSize: "12px",
                wordWrap: "break-word",
              }}>
              <span style={{ fontWeight: "bold" }}>Provinsi:</span>
              <br />
              {userRole === "Super Admin" ? "-" : userProvince}
            </div>
            <div
              style={{
                color: "black",
                fontSize: "12px",
                wordWrap: "break-word",
              }}>
              <span style={{ fontWeight: "bold" }}>Kabupaten / Kota:</span>
              <br />
              {userRole === "Super Admin" ? "-" : userCity}
            </div>
            <div
              style={{
                color: "black",
                fontSize: "12px",
                wordWrap: "break-word",
              }}>
              <span style={{ fontWeight: "bold" }}>Periode:</span>
              <br />
              ke-{periodsId} ({periodsMonth} - {periodsYear})
            </div>
          </div>
        )} */}

        <div className="mb-3"></div>

        {/* <hr className="sidebar-divider d-none d-md-block mt-2" /> */}
        {loading && (
          <Spinner
            animation="border"
            variant="light"
            className="d-flex mx-auto"
          ></Spinner>
        )}
        {!loading &&
          filteredMenuItems.map((item, i) => {
            return item.subMenu && item.subMenu.length > 0 ? (
              <li className="nav-item" key={`Menu-${i}`}>
                <NavLink
                  className={isActiveMenu === item.title ? "" : "collapsed"}
                  data-toggle="collapse"
                  aria-expanded={isActiveMenu === item.title ? true : false}
                  onClick={() => handleActiveMenu(item.title)}
                >
                  <img src={item.logopath} alt=""></img>
                  <span className="text-white ml-2">{item.title}</span>
                </NavLink>
                <div
                  id="collapseTwo"
                  className={
                    isActiveMenu === item.title ? "collapse show" : "collapse"
                  }
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div
                    className="py-2 collapse-inner rounded"
                    style={{ backgroundColor: "#CA534D" }}
                  >
                    {/* <h6 className="collapse-header text-white">{item.title}:</h6> */}
                    {/* {
                                        item.subMenu.map((sub, k) => {
                                            return <Button key={`SubMenu-${k}`} variant="link" className="collapse-item pt-0 text-white" onClick={() => handleSubMenuClick(sub.path)}>
                                                <i className={sub.icon}></i><span> {sub.title}</span></Button>
                                        })
                                    } */}
                    {item.subMenu.map((sub, k) => {
                      if (!sub.hidden)
                        return (
                          <Button
                            key={`SubMenu-${k}`}
                            variant="link"
                            className="collapse-item"
                            onClick={() => handleSubMenuClick(sub.path)}
                          >
                            <i
                              style={{
                                color: "white",
                                marginRight: 5,
                                marginLeft: 10,
                              }}
                              className={sub.icon}
                            ></i>
                            <span className="text-white">{sub.title}</span>
                          </Button>
                        );
                    })}
                  </div>
                </div>
                {/* <hr className="sidebar-divider" /> */}
              </li>
            ) : (
              <li
                className={
                  isActiveMenu === item.path ? "nav-item active" : "nav-item"
                }
                key={`Menu-${i}`}
              >
                <Button
                  variant="link"
                  className="nav-link pt-0 text-white"
                  onClick={() => handleMenuClick(item.path)}
                >
                  {/* <i className={item.icon}></i>
                   */}
                  <img
                    src={item.logopath}
                    alt=""
                    style={{
                      backgroundColor: "black",
                      borderRadius: 8,
                      padding: 2.5,
                    }}
                  ></img>
                  <span className="ml-2">{item.title}</span>
                </Button>
                <hr className="sidebar-divider" />
              </li>
            );
          })}

        {/* <button
          onClick={handleLogout}
          className="mb-3 p-2"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            border: "none",
            color: "white",
          }}>
          <i className="fas fa-sign-out-alt"></i>
        </button> */}
      </ul>
    </div>
  );
};

export default Sidebar;
