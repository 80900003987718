import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch } from "redux/store";
import { tokenAPICALL } from "services/authService";
import {
  resetPeriodsToInit,
  setJWTToken,
  setPeriodsList,
  setPeriodsMessage,
  setUserProfie,
  startLoadingPeriods,
  stopLoadingPeriods,
} from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
import { getPeriods } from "services/periodsService";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.authToken);
  const periodsLoading = useSelector(
    (state: RootState) => state.periods.loading
  );
  const periodsData = useSelector((state: RootState) => state.periods);
  const [selectedPeriods, setSelectedPeriods] = useState("0");
  const token = localStorage.getItem("token");
  const roles = localStorage.getItem("user");
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("");
  const handlePeriodsChange = (event) => {
    const newValue = event.target.value;
    setSelectedPeriods(newValue);
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      const response = await tokenAPICALL(
        values.username,
        values.password,
        "web"
      );
      if (response) {
        setLoading(false);
        dispatch(setUserProfie(response.userProfile));
        dispatch(setJWTToken(response));
        localStorage.setItem("user", response.userProfile.role.role_name);
        localStorage.setItem("userEmail", response.userProfile.email);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem(
          "province_id",
          response.userProfile?.provinces?.province_id ?? null
        );
        localStorage.setItem(
          "province_name",
          response.userProfile?.provinces?.province_name ?? null
        );
        localStorage.setItem(
          "city_id",
          response.userProfile?.cities?.city_id ?? null
        );
        localStorage.setItem(
          "city_name",
          response.userProfile?.cities?.city_name ?? null
        );
        localStorage.setItem(
          "district_id",
          response.userProfile?.districts?.district_id ?? null
        );
        localStorage.setItem(
          "district_name",
          response.userProfile?.districts?.district_name ?? null
        );
        localStorage.setItem(
          "subdistrict_id",
          response.userProfile?.subdistricts?.subdistrict_id ?? null
        );
        localStorage.setItem(
          "subdistrict_name",
          response.userProfile?.subdistricts?.subdistrict_name ?? null
        );
        localStorage.setItem("periode_id", null);
        setSection("chooseperiod");
        if (periodsData && periodsData.list && periodsData.list.length === 0) {
          dispatch(resetPeriodsToInit());
          dispatch(startLoadingPeriods());
          getPeriods(
            Constant.defaultPageNumber,
            Constant.defaultDropdownPageSize,
            ""
          ).then((response) => {
            if (response && response.records) {
              dispatch(
                setPeriodsList({
                  pageNo: Constant.defaultPageNumber,
                  pageSize: Constant.defaultDropdownPageSize,
                  list: response.records,
                  totalCount: response.total_count,
                  searchKey: "",
                })
              );
              dispatch(stopLoadingPeriods());
            } else {
              dispatch(setPeriodsMessage("Data kosong"));
              dispatch(stopLoadingPeriods());
            }
          });
        }
        dispatch(setError(""));
      } else {
        dispatch(setError("Invalid Credentials"));
        setLoading(false);
      }
    },
    validationSchema: yup.object({
      username: yup.string().trim().required("Username is required"),
      password: yup.string().trim().required("Password is required"),
    }),
  });

  const handlePeriods = () => {
    const periods = JSON.parse(selectedPeriods);
    localStorage.setItem("periode_id", periods?.id);
    localStorage.setItem("periode_year", periods?.year);
    localStorage.setItem("periode_month", periods?.month);
    navigate(
      roles === "Super Admin"
        ? "/pbp"
        : roles === "Admin / Juru Serah"
        ? "/bnba"
        : roles === "Admin STPJM"
        ? "/sptjm"
        : roles === "Admin Reset"
        ? "/dashboard-images"
        : "/dashboard",
      { replace: true, state: { from: "login" } }
    );
    navigate(0);
  };

  useEffect(() => {
    document.body.classList.toggle("bg-gradient-primary", true);
  }, []);
  return (
    <div className="bg-login">
      <div className="login-form">
        <img src="../../logos/logo-bingkis.png" alt="" className="login-logo" />
      </div>
      <div className="" style={{ backgroundColor: "#CC251D", width: "100%" }}>
        <div className="login-body">
          <div className="login-head">
            <h4>
              Welcome to{" "}
              <span className="text-primary font-weight-bold bingkis-font">
                Bonceng
              </span>
            </h4>
            <h1 className="mt-4 text-dark">Login</h1>
          </div>
          {section !== "chooseperiod" ? (
            <Form className="user mt-3" onSubmit={formik.handleSubmit}>
              <Form.Group>
                <label className="form-control-label">Username</label>
                <Form.Control
                  type="text"
                  name="username"
                  className="form-control-user form-login"
                  value={formik.values.username}
                  placeholder="Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    !!formik.touched.username && !!formik.errors.username
                  }
                  isValid={!!formik.touched.username && !formik.errors.username}
                ></Form.Control>
                {formik.errors.username && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.username}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group>
                <label className="form-control-label">Password</label>
                <Form.Control
                  type="password"
                  name="password"
                  className="form-control-user form-login"
                  value={formik.values.password}
                  placeholder="Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    !!formik.touched.password && !!formik.errors.password
                  }
                  isValid={!!formik.touched.password && !formik.errors.password}
                ></Form.Control>
                {formik.errors.password && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Button
                style={{ borderRadius: 10 }}
                type="submit"
                className="btn-user btn-block"
                disabled={loading}
              >
                {loading && (
                  <Spinner
                    variant="light"
                    animation="border"
                    size="sm"
                    className="mr-1"
                  ></Spinner>
                )}
                Login
              </Button>
              <div style={{ textAlign: "center", marginTop: "2rem" }}>
                Copyright &copy; Bonceng All rights reserved.
              </div>

              {rData.errorMessage ? (
                <>
                  <hr />
                  <Alert
                    variant={Constant.defaultAlertVarient}
                    className="alert-dismissible fade"
                  >
                    {rData.errorMessage}
                    <Button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                      onClick={() => dispatch(setError(""))}
                    >
                      <span aria-hidden="true">&times;</span>
                    </Button>
                  </Alert>
                </>
              ) : null}
            </Form>
          ) : (
            <Form className="user mt-3">
              <Form.Group controlId="exampleForm.SelectCustom">
                <label htmlFor="">Pilih Periode</label>
                <Form.Control
                  as="select"
                  custom
                  onChange={handlePeriodsChange}
                  value={selectedPeriods}
                  required
                >
                  <option disabled value={0}>
                    {periodsLoading ? "Loading..." : "Pilih Periode"}
                  </option>
                  {periodsData.list.map((item, i) => {
                    return (
                      <option value={item.id} key={`periods-${i}`}>
                        {item.id} ({item.month} - {item.year})
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Button
                style={{ borderRadius: 10 }}
                type="submit"
                className="btn-user btn-block"
                variant="primary"
                onClick={() => handlePeriods()}
                disabled={selectedPeriods === "0"}
              >
                Lanjutkan
              </Button>
            </Form>
          )}
        </div>
      </div>
    </div>
    // <div className="container">
    //     <div className="row justify-content-center">
    //         <div className="col-xl-10 col-lg-12 col-md-9">
    //             <div className="card o-hidden border-0 shadow-lg my-5">
    //                 <div className="card-body p-0">
    //                     <div className="row">
    //                         <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
    //                         {section != 'chooseperiod' ? <div className="col-lg-6">
    //                             <div className="p-5">
    //                                 <Form className="user" onSubmit={formik.handleSubmit}>
    //                                     <Form.Group>
    //                                         <label className="form-control-label">Username</label>
    //                                         <Form.Control type="text" name="username" className="form-control-user" value={formik.values.username}
    //                                             onChange={formik.handleChange}
    //                                             onBlur={formik.handleBlur}
    //                                             isInvalid={!!formik.touched.username && !!formik.errors.username}
    //                                             isValid={!!formik.touched.username && !formik.errors.username}
    //                                         ></Form.Control>
    //                                         {formik.errors.username && (
    //                                             <Form.Control.Feedback type="invalid">
    //                                                 {formik.errors.username}
    //                                             </Form.Control.Feedback>
    //                                         )}
    //                                     </Form.Group>
    //                                     <Form.Group>
    //                                         <label className="form-control-label">Password</label>
    //                                         <Form.Control type="password" name="password" className="form-control-user" value={formik.values.password}
    //                                             onChange={formik.handleChange}
    //                                             onBlur={formik.handleBlur}
    //                                             isInvalid={!!formik.touched.password && !!formik.errors.password}
    //                                             isValid={!!formik.touched.password && !formik.errors.password}
    //                                         ></Form.Control>
    //                                         {formik.errors.password && (
    //                                             <Form.Control.Feedback type="invalid">
    //                                                 {formik.errors.password}
    //                                             </Form.Control.Feedback>
    //                                         )}
    //                                     </Form.Group>
    //                                     <Button type="submit" className="btn-user btn-block" variant="primary" disabled={loading}>{loading && <Spinner variant="light" animation="border" size="sm" className="mr-1"></Spinner>}Login</Button>
    //                                 </Form>
    //                                 <hr />
    //                                 {rData.errorMessage ?
    //                                     <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.errorMessage}
    //                                         <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setError(''))}>
    //                                             <span aria-hidden="true">&times;</span>
    //                                         </Button>
    //                                     </Alert> : null}

    //                                 <div className="copyright text-center my-auto">
    //                                     <span>Copyright &copy; YAONS 2023</span>
    //                                 </div>
    //                             </div>
    //                         </div> :
    //                             <div className="col-lg-6">
    //                                 <div className="p-5">
    //                                     <Form className="user">
    //                                         <Form.Group controlId="exampleForm.SelectCustom">
    //                                             <label htmlFor="">Pilih Periode</label>
    //                                             <Form.Control as="select" custom onChange={handlePeriodsChange} value={selectedPeriods} required>

    //                                                 <option disabled value={0}>{periodsLoading ? 'Loading...' : 'Pilih Periode'}</option>
    //                                                 {
    //                                                     periodsData.list.map((item, i) => {
    //                                                         return <option value={JSON.stringify(item)} key={`periods-${i}`}>{item.id} ({item.month} - {item.year})</option>
    //                                                     })}
    //                                             </Form.Control>
    //                                         </Form.Group>
    //                                         <Button type="submit" className="btn-user btn-block" variant="primary" onClick={() => handlePeriods()} disabled={selectedPeriods === '0'}>Lanjutkan</Button>
    //                                     </Form>
    //                                     <hr />
    //                                     {rData.errorMessage ?
    //                                         <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.errorMessage}
    //                                             <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setError(''))}>
    //                                                 <span aria-hidden="true">&times;</span>
    //                                             </Button>
    //                                         </Alert> : null}

    //                                     <div className="copyright text-center my-auto">
    //                                         <span>Copyright &copy; YAONS 2023</span>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         }

    //                     </div>
    //                 </div>
    //             </div>

    //         </div>

    //     </div>

    // </div>
  );
};

export default Login;
