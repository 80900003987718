import React, { useState } from "react";
import { Alert, Button, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  setKpmsList,
  setKpmsMessage,
  startLoadingKpms,
  stopLoadingKpms,
} from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getKpms } from "services/kpmsService";
import Layout from "template";
import { Constant } from "template/Constant";
import { KpmsForm } from "./form";
import { KpmsTable } from "./table";
import { KpmsUploadImages } from "./upload/images";
import { useLocation } from "react-router";

type Props = {
  section?: any;
};

export const Kpms: React.FC<Props> = ({ section }) => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.kpms);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState("");
  const [editAction, setEditAction] = useState("");
  const [isFiltered, setIsFiltered] = useState(undefined);
  const getData = (
    page,
    pageSize,
    searchKey,
    periode_id,
    date,
    province_id,
    city_id,
    district_id,
    subdistrict_id
  ) => {
    dispatch(startLoadingKpms());
    getKpms(
      page,
      pageSize,
      searchKey,
      periode_id,
      date,
      province_id,
      city_id,
      district_id,
      subdistrict_id,
      section
    ).then((response) => {
      if (response && response.records) {
        dispatch(
          setKpmsList({
            pageNo: page,
            pageSize: pageSize,
            list: response.records,
            totalCount: response.total_count,
            searchKey: searchKey,
            lastPage: response.lastPage,
          })
        );
        dispatch(stopLoadingKpms());
      } else {
        dispatch(setKpmsMessage("Data kosong"));
        dispatch(stopLoadingKpms());
      }
    });
  };

  const handleRowEdit = (rowData, editAction) => {
    setRow(rowData);
    dispatch(setKpmsMessage(""));
    setAction("edit");
    setEditAction(editAction);
  };

  const handleRowDetail = (
    rowData,
    isSelectedProvince,
    isSelectedCity,
    isSelectedDistrict,
    isSelectedSubDistrict
  ) => {
    setRow(rowData);
    dispatch(setKpmsMessage(""));
    setAction("detail");
    setIsFiltered({
      isProvinceSelected: isSelectedProvince,
      isCitySelected: isSelectedCity,
      isDistrictSelected: isSelectedDistrict,
      isSubDistrictSelected: isSelectedSubDistrict,
    });
  };
  const location = useLocation();
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between">
          <Card style={{ border: "none"}}>
            <Card.Header style={{ backgroundColor: "white", border: "none" }} className="py-0">
              <h1 className="h4 mb-0 font-weight-bold text-dark">
                {section === "images"
                  ? "Dashboard Foto"
                  : location?.pathname.startsWith("/verval")
                  ? "VERVAL"
                  : "PBP"}
              </h1>
            </Card.Header>
          </Card>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message ? (
            <Alert
              variant={Constant.defaultAlertVarient}
              className="alert-dismissible fade"
            >
              {rData.message}
              <Button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => dispatch(setKpmsMessage(""))}
              >
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert>
          ) : null}
          {section === "add" || section === "detail" || action ? (
            <KpmsForm
              hideShowForm={setAction}
              action={action || section}
              row={row}
              getData={getData}
              isFiltered={isFiltered}
              editAction={editAction}
            />
          ) : section === "upload-images" ? (
            <KpmsUploadImages />
          ) : (
            <KpmsTable
              section={section}
              handleRowEdit={handleRowEdit}
              handleRowDetail={handleRowDetail}
              hideShowForm={setAction}
              getData={getData}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
