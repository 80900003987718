import React, { useEffect } from "react";
import {
    Card,
} from "react-bootstrap";
import Layout from "template";

import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import ChartComponent from "./Chart";
import { ShipmentsTable } from "./table";
import { setDashboardList, setPengirimanList, setPengirimanMessage, startLoadingDashboard, startLoadingPengiriman, stopLoadingDashboard, stopLoadingPengiriman } from "redux/actions";
import MapComponent from "./MapChart";
import { getAllDashboardReports } from "services/dashboardService";
import { getPengiriman } from "services/pengirimanService";
import { useAppDispatch } from "redux/store";
import { ReportsTable } from "./tableReport";
import TotalCard from "./TotalCard";

export const DashboardV3: React.FC = () => {
    const dispatch = useAppDispatch();
    const dashboardLoading = useSelector((state: RootState) => state.dashboard.loading);
    const dashboardData = useSelector((state: RootState) => state.dashboard);
    const periodsId = localStorage.getItem('periode_id')
    // const getData = (page, pageSize, searchKey, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    //     dispatch(startLoadingPengiriman())
    //     getPengiriman(page, pageSize, searchKey, periode_id, date, province_id, city_id, district_id, subdistrict_id).then((response) => {
    //         if (response && response.records) {
    //             dispatch(setPengirimanList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
    //             dispatch(stopLoadingPengiriman())
    //         } else {
    //             dispatch(setPengirimanMessage("Data kosong"));
    //             dispatch(stopLoadingPengiriman())
    //         }
    //     })
    // }
    const getDashboardData = () => {
        dispatch(startLoadingDashboard())
        getAllDashboardReports(periodsId).then(((response) => {
            if (response) {
                dispatch(setDashboardList({ list: response.data }))
                dispatch(stopLoadingDashboard())
            }
        }))
    }
    useEffect(() => {
        // if (dashboardData && dashboardData.list && dashboardData.list.length === 0) {
        getDashboardData()
        // }
    }, [])
    return (
        <Layout>
            <div className="container-fluid">
                <div className="d-card d-flex justify-content-between justify-content-lg-center flex-column flex-md-row flex-wrap" style={{ gap: '12px' }}>
                    {/* <Card style={{ borderRadius: 20, border: 'none', padding: 10 }} className="card-counter">
                        <Card.Body>
                            <Card.Title><i className="far fa-user mr-2"></i>Total Alokasi</Card.Title>
                            <Card.Text style={{ fontSize: 32, color: '#040D12', fontWeight: "bold" }}>
                                {dashboardLoading ? <Skeleton /> : dashboardData?.list?.total_alokasi}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card style={{ borderRadius: 20, border: 'none', padding: 10 }} className="card-counter">
                        <Card.Body>
                            <Card.Title><i className="far fa-user mr-2"></i>Total Diproses</Card.Title>
                            <Card.Text style={{ fontSize: 32, color: '#040D12', fontWeight: "bold" }}>
                                {dashboardLoading ? <Skeleton /> : dashboardData?.list?.total_process}
                            </Card.Text>
                        </Card.Body>
                    </Card> */}
                    {/* <Card style={{ borderRadius: 20, border: 'none', padding: 10 }} className="card-counter">
                        <Card.Body>
                            <Card.Title><i className="far fa-user mr-2"></i>Total Diserahkan</Card.Title>
                            <Card.Text style={{ fontSize: 32, color: '#040D12', fontWeight: "bold" }}>
                                {dashboardLoading ? <Skeleton /> : dashboardData?.list?.total_handover}
                            </Card.Text>
                        </Card.Body>
                    </Card> */}
                    {/* <Card style={{ borderRadius: 20, border: 'none', padding: 10 }} className="card-counter">
                        <Card.Body>
                            <Card.Title><i className="far fa-user mr-2"></i>Total Selesai</Card.Title>
                            <Card.Text style={{ fontSize: 32, color: '#040D12', fontWeight: "bold" }}>
                                {dashboardLoading ? <Skeleton /> : dashboardData?.list?.total_completed}
                            </Card.Text>
                        </Card.Body>
                    </Card> */}
                </div>
                <div className="d-lg-flex w-100 mt-4">
                    <TotalCard data={dashboardData} loading={dashboardLoading}/>
                    <MapComponent data={dashboardData} loading={dashboardLoading} />
                    
                </div>
                <div className="w-100 mt-4">
                    <ChartComponent data={dashboardData} loading={dashboardLoading} />
                </div>
                <div className="sj-table">
                    {/* <ShipmentsTable getData={getData} /> */}
                    <ReportsTable />
                </div>
            </div>
        </Layout>
    );
};
