import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";

const TotalCard = (props) => {
  const { data, loading } = props;
  return (
    <div>
      <div className="card-chart shadow-sm bg-white">
        <p className="font-weight-bold text-dark">Total Keseluruhan Proses</p>
        <p>Data diambil berdasarkan data dilapangan</p>
        <div className="circle-chart">
          <div className="circle">
            <div className="content">
              <div className="">Total Alokasi</div>
              <div className="" style={{ fontSize: 24 }}>
                {" "}
                {loading ? <Skeleton style={{width:"80px"}}/> : data?.list?.total_alokasi}
              </div>
            </div>
          </div>
          <div className="circle-2">
            <div className="content">
              <div className="">Total Diproses</div>
              <div className="" style={{ fontSize: 24 }}>
                {" "}
                {loading ? <Skeleton style={{width:"80px"}}/> : data?.list?.total_process}
              </div>
            </div>
          </div>
          <div className="circle-3">
            <div className="content">
              <div className="">Total Selesai</div>
              <div className="" style={{ fontSize: 24 }}>
                {" "}
                {loading ? <Skeleton style={{width:"80px"}}/> : data?.list?.total_completed}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalCard;
